// the following code handles the multistep navigation
import Rails from "@rails/ujs";
import RailsAjax from "../shared/rails-ajax";

var currentFieldset, nextFieldset, previousFieldset, navIndex; //fieldsets and index

let cancelButton = document.querySelector("[data-mdc-dialog-action='cancel']");
let form = document.querySelector("form.iat-multistep");
let triggerButton = document.getElementById("iat-dialog-trigger");

function sendPageViewEvent(pageNumber) {
  RailsAjax.post(form.dataset.apiUrl, {
    iat_quiz_page_view: {
      page_number: pageNumber
    }
  });
}

function setDisabled() {
  if (form) {
    let mdcSelects = document.querySelectorAll(".mdc-select");
    let selectInputs = document.querySelectorAll(
      "input[data-mdc--select-target='hiddenInput']"
    );
    let mdcTextAreas = document.querySelectorAll(".mdc-text-field");
    let mdcTextAreaInputs = document.querySelectorAll(
      "textarea.mdc-text-field__input"
    );

    selectInputs.forEach((input, i) => {
      if (input.value !== "") {
        mdcSelects[i].classList.add("mdc-select--disabled");
      }
    });

    mdcTextAreaInputs.forEach((input, i) => {
      if (input.value !== "") {
        input.disabled = true;
        mdcTextAreas[i].classList.add("mdc-text-field--disabled");
      }
    });
  }
}

setDisabled();

if (cancelButton && cancelButton[0] != null) {
  sendPageViewEvent("1");
}

let iatNavNext = document.querySelector("button.iat-multistep.nav-next");
if (iatNavNext) {
  iatNavNext.addEventListener("click", function () {
    Rails.fire(form, "submit");

    let incomplete = false;

    document
      .querySelectorAll("#show-modal-unless-complete input[type='hidden']")
      .forEach((pseudoRequired) => {
        if (pseudoRequired.value === "") {
          incomplete = true;
        }
      });

    if (incomplete) {
      triggerButton.click();
    } else {
      document.querySelectorAll(".form-step").forEach((formStep) => {
        if (window.getComputedStyle(formStep).display !== "none") {
          formStep.querySelectorAll("mdc-select").forEach((element) => {
            element.classList.add("mdc-select--disabled");
          });
        }
      });
      navNext();
    }
  });
}

function navNext() {
  document.querySelectorAll(".form-step").forEach((step, i) => {
    if (step.offsetParent !== null) {
      currentFieldset = step;
      nextFieldset = step.nextElementSibling;
      navIndex = i;
    }
  });

  if (nextFieldset === null) {
    window.location.href = window.location.origin + iatNavNext.dataset.nextPath;
    return;
  }

  let navSteps = document.querySelectorAll("#progressbar li");

  navSteps[navIndex].classList.remove("active");
  navSteps[navIndex + 1].classList.add("active");
  navSteps[navIndex + 1].classList.add("activated");

  nextFieldset.style.display = "block";
  currentFieldset.style.display = "none";
  sendPageViewEvent(navIndex + 2);
  setDisabled();
}

let iatNavBack = document.querySelector("button.iat-multistep.nav-back");
if (iatNavBack) {
  iatNavBack.addEventListener("click", navBack);
}

function navBack() {
  document.querySelectorAll(".form-step").forEach((step, i) => {
    if (step.offsetParent !== null) {
      currentFieldset = step;
      previousFieldset = currentFieldset.previousElementSibling;
      navIndex = i;
    }
  });

  if (previousFieldset.id === "iat_quiz_id") {
    window.location.href = window.location.origin + iatNavBack.dataset.nextPath;
    return;
  }

  let navSteps = document.querySelectorAll("#progressbar li");

  navSteps[navIndex].classList.remove("activated");
  navSteps[navIndex].classList.remove("active");
  navSteps[navIndex - 1].classList.add("active");
  navSteps[navIndex - 1].classList.add("activated");

  currentFieldset.style.display = "none";
  previousFieldset.style.display = "block";
  sendPageViewEvent(navIndex);
}
