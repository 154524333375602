import RailsAjax from "../../shared/rails-ajax";

let submitWithDisclaimer = document.getElementById("form-disclaimer-trigger");

let modal = document.getElementById("modal-form-disclaimer-container");

if (submitWithDisclaimer) {
  submitWithDisclaimer.addEventListener("click", () => {
    document.getElementById("modal-form-disclaimer-title").innerHTML =
      submitWithDisclaimer.dataset.modalTitle;

    let acceptButton = document.getElementById("form-disclaimer-accept");
    acceptButton.innerHTML = submitWithDisclaimer.dataset.submitWith;

    let cancelButton = document.getElementById("form-disclaimer-decline");
    cancelButton.innerHTML = submitWithDisclaimer.dataset.cancelWith;

    document.getElementById("form-disclaimer-text").innerHTML =
      submitWithDisclaimer.dataset.disclaimerText;
  });

  function createEmailDisclaimerResponse(accepted) {
    RailsAjax.post(modal.dataset.emailDisclaimerResponsePostUrl, {
      email_disclaimer_response: {
        accepted: accepted,
        email_prompt: modal.dataset.controllerName
      }
    });
  }

  function closeModal() {
    createEmailDisclaimerResponse(false);
  }

  document
    .getElementById("form-disclaimer-decline")
    .addEventListener("click", closeModal);

  document
    .querySelector(".mdc-dialog__close")
    .addEventListener("click", closeModal);
  document
    .getElementById("form-disclaimer-accept")
    .addEventListener("click", () => {
      createEmailDisclaimerResponse(true);
    });
}
