document.addEventListener("DOMContentLoaded", function () {
  function setSelect() {
    let selects = document.querySelectorAll("select");
    if (window.selectpicker && window.selectpicker.Constructor) {
      window.selectpicker.Constructor.BootstrapVersion = "4.4.1";

      if (selects.length > 0) {
        let initialized = true;

        selects.forEach((select) => {
          if (typeof select.selectpicker === "function") {
            select.selectpicker({ size: 4 });
          } else {
            initialized = false;
          }
        });
        if (initialized) {
          return;
        }
      }
    }
    setTimeout(setSelect, 25);
  }
  setTimeout(setSelect, 75);
});
