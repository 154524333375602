Array.from(document.querySelectorAll("label.rating-star")).forEach(
  function (star) {
    star.addEventListener("click", function (clickedStar) {
      removeActive();
      markStars(this.id);
      event.stopImmediatePropagation();
    });
    star.addEventListener("mouseover", function () {
      hoverStars(this.id);
    });
    star.addEventListener("mouseout", function () {
      removeHover(this.id);
    });
  }
);

function hoverStars(id) {
  for (id; id > 0; id--) {
    document.getElementById(id).classList.add("star-hover");
  }
}

function markStars(id) {
  for (id; id > 0; id--) {
    document.getElementById(id).classList.add("active");
  }
}

function removeActive() {
  document.querySelectorAll("label.rating-star").forEach(function (s) {
    s.classList.remove("active");
  });
}

function removeHover(id) {
  for (id; id > 0; id--) {
    document.getElementById(id).classList.remove("star-hover");
  }
}
