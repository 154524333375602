let goals = Array.from(document.querySelectorAll(".goal-setting-goal"));
let otherGoal = document.querySelector(".goal-setting-other-goal");
let form = document.querySelector("#goal-setting-goals-form");
let submitGroup = Array.from(
  document.querySelectorAll("#goal-setting-goals-form .display-post-select")
);

if (form != null) {
  let requiredGoals = form.dataset.requiredGoals;

  function numberOfSelectedGoals() {
    return document.querySelectorAll("input[type='checkbox']:checked").length;
  }

  function numberOfOptionalGoals() {
    let otherGoalTitle =
      document.getElementById("participant_other_goal_attributes_title") ||
      document.getElementById(
        "participant_three_month_booster_other_goals_attributes_title"
      ) ||
      document.getElementById(
        "participant_six_month_booster_other_goals_attributes_title"
      );
    if (otherGoalTitle.value.trim() != "") {
      otherGoal.querySelector("label").classList.add("mdc-text-field--focused");
      return 1;
    }
    return 0;
  }

  function allRequiredGoalsSelected() {
    return numberOfSelectedGoals() + numberOfOptionalGoals() == requiredGoals;
  }

  function maybeShowSubmit() {
    if (submitGroup == null) return;
    for (let element of submitGroup) {
      element.classList.toggle("d-none", !allRequiredGoalsSelected());
    }
  }

  function disableOtherGoal() {
    otherGoal.querySelector("textarea").disabled = true;
  }

  function enableOtherGoal() {
    otherGoal.querySelector("textarea").disabled = false;
  }

  goals.forEach(function (goal) {
    let checkbox = goal.querySelector("input");
    let marker = goal.querySelector(".answer-marker");

    function updateSelection() {
      // checkbox.checked sometimes incorrectly returns false
      if (checkbox.checked || checkbox.getAttribute("checked") == "checked") {
        goal.classList.add("border-success", "text-success");
        marker.classList.remove("invisible");
      } else {
        goal.classList.remove("border-success", "text-success");
        marker.classList.add("invisible");
      }
    }

    function toggleSelection() {
      if (checkbox.disabled) {
        return;
      }

      if (allRequiredGoalsSelected() && !checkbox.checked) {
        return;
      }

      checkbox.checked = !checkbox.checked;
      updateSelection();

      if (allRequiredGoalsSelected() && numberOfOptionalGoals() == 0) {
        disableOtherGoal();
      } else {
        enableOtherGoal();
      }
    }

    function handleResponse() {
      toggleSelection();
      maybeShowSubmit();
    }

    goal.addEventListener("click", handleResponse, false);
    goal.addEventListener(
      "keypress",
      (e) => {
        const SPACEBAR = 32;
        if (e.keyCode == SPACEBAR) {
          handleResponse();
        }
      },
      false
    );
    updateSelection();
  });

  if (otherGoal != null) {
    otherGoal.addEventListener("input", maybeShowSubmit, false);
  }
}
