function showElement(elementId) {
  let element = document.getElementById(elementId);

  element.classList.add("show");
  element.querySelectorAll("input").forEach((input) => {
    input.disabled = false;
  });
}

function hideElement(elementId) {
  let element = document.getElementById(elementId);

  element.classList.remove("show");
  element.querySelectorAll("input").forEach((input) => {
    input.disabled = true;
  });
}

// Branching answers reveal or hide questions that follow based on the one chosen
let branchingAnswers = Array.from(
  document.querySelectorAll(".branching-answer")
);

branchingAnswers.forEach((answer) => {
  function handleBranching() {
    if (answer.checked && answer.value == answer.dataset.showValue) {
      showElement(answer.dataset.showId);
    } else if (answer.checked && answer.value != answer.dataset.showValue) {
      hideElement(answer.dataset.showId);
    }
  }

  answer.addEventListener("change", handleBranching, true);
});

// Feedback answers display feedback dependent on which answer is chosen
let feedbackAnswers = Array.from(document.querySelectorAll(".feedback-answer"));

feedbackAnswers.forEach((answer) => {
  function feedbackIdFromAnswer(value) {
    let map = JSON.parse(answer.dataset.feedbackMap);

    return map.find((mapping) => {
      return mapping.label == value;
    }).feedback_id;
  }

  function handleFeedback() {
    document.querySelectorAll(".answer-feedback").forEach((feedback) => {
      feedback.classList.remove("show");
    });

    if (answer.checked) {
      showElement(feedbackIdFromAnswer(answer.value));
    }
  }

  answer.addEventListener("change", handleFeedback, true);
});

// Branching answer pairs reveal or hide questions that follow based on a combination of two chosen
let branchingAnswerPairs = Array.from(
  document.querySelectorAll(".branching-answer-pair")
);

branchingAnswerPairs.forEach((answerPair) => {
  let inputs = answerPair.querySelectorAll("input");
  let showConditions = JSON.parse(answerPair.dataset.showConditions);

  function handleResponse() {
    let inputResponses = [];
    inputs.forEach((input) => inputResponses.push(input));
    inputResponses = inputResponses
      .filter((input) => input.checked)
      .map((input) => input.value);

    if (inputResponses.length != 2) {
      return;
    }

    hideElement(answerPair.dataset.showId);

    showConditions.forEach((conditions) => {
      if (
        inputResponses[0] == conditions[0] &&
        inputResponses[1] == conditions[1]
      ) {
        showElement(answerPair.dataset.showId);
      }
    });
  }

  inputs.forEach((input) => {
    input.addEventListener("change", handleResponse, true);
  });
});

// Required checkboxes must have at least one box checked
let requiredCheckboxes = Array.from(
  document.querySelectorAll(".required-checkboxes")
);

requiredCheckboxes.forEach((checkbox) => {
  checkbox.addEventListener("click", (event) => {
    let checkboxGroupClass = event.target.dataset.groupClass;
    let groupCheckboxes = document.querySelectorAll("." + checkboxGroupClass);

    let atLeastOneChecked = Array.prototype.some.call(
      groupCheckboxes,
      (groupCheckbox) => {
        return groupCheckbox.checked;
      }
    );

    groupCheckboxes.forEach((groupCheckbox) => {
      groupCheckbox.required = !atLeastOneChecked;
    });
  });
});

// the following code handles the multistep follow-up navigation
import Rails from "@rails/ujs";

let multistepButton = document.querySelector("button.multistep.nav-next");

if (multistepButton) {
  multistepButton.addEventListener("click", function () {
    let form = document.querySelector("form.multistep");
    let inputFieldset = document.getElementById("input-fieldset");
    if (!isElementHidden(inputFieldset) && this.dataset.submit == "true") {
      if (form.reportValidity()) {
        Rails.fire(form, "submit");
        navNext();
      }
    } else {
      navNext();
    }
  });
}

function navNext() {
  let formSteps = document.querySelectorAll(".form-step");
  let currentFieldset = [...formSteps].find(
    (step) => isElementHidden(step) === false
  );
  let nextFieldset = currentFieldset.nextElementSibling;
  let currentIndex = [...formSteps].indexOf(currentFieldset);
  let nextIndex = [...formSteps].indexOf(nextFieldset);
  let progressbarItems = document.querySelectorAll("#progressbar li");

  if (nextFieldset === null) {
    window.location.href =
      window.location.origin +
      document.querySelector("button.multistep.nav-next").dataset.nextPath;

    return;
  }

  if (currentIndex !== -1 && progressbarItems[currentIndex]) {
    progressbarItems[currentIndex].classList.remove("active");
  }
  //activate next step on progressbar using the index of nextFieldset
  if (currentIndex !== -1 && progressbarItems[nextIndex]) {
    progressbarItems[nextIndex].classList.add("active");
    progressbarItems[nextIndex].classList.add("activated");
  }

  showNextStep(nextFieldset);
  hideCurrentStep(currentFieldset);
}

function isElementHidden(el) {
  if (window.getComputedStyle(el).display === "none") {
    return true;
  } else {
    return false;
  }
}

function showNextStep(el) {
  el.style.display = "block";
}

function hideCurrentStep(el) {
  el.style.display = "none";
}
