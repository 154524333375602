import RailsAjax from "../../shared/rails-ajax";

let submitWithDisclaimers = Array.from(
  document.querySelectorAll(".submit-path-with-disclaimer")
);

let modal = document.getElementById("modal-path-disclaimer-container");

function addParamsToForm(form, params) {
  JSON.parse(params).forEach(function (pair) {
    form.innerHTML =
      form.innerHTML +
      `<input type="hidden" name="${pair[0]}" value="${pair[1]}">`;
  });
}

function createEmailDisclaimerResponse(accepted, url, name) {
  RailsAjax.post(url, {
    email_disclaimer_response: {
      accepted: accepted,
      email_prompt: name
    }
  });
}

submitWithDisclaimers.forEach(function (disclaimerButton) {
  disclaimerButton.addEventListener("click", function () {
    let form = document.getElementById("modal_path_disclaimer_form");
    form.action = this.dataset.path;

    if (this.dataset.params != null) {
      addParamsToForm(form, this.dataset.params);
    }

    document.getElementById("modal-path-disclaimer-title").innerHTML =
      this.dataset.modalTitle;
    let acceptButton = document.getElementById("path-disclaimer-accept");
    acceptButton.querySelector(".mdc-button__label").innerHTML =
      this.dataset.submitWith;

    let cancelButton = document.getElementById("path-disclaimer-decline");
    cancelButton.querySelector(".mdc-button__label").innerHTML =
      this.dataset.cancelWith;

    document.getElementById("path-disclaimer-text").innerHTML =
      this.dataset.disclaimerText;
  });
});

const disclaimerDecline = document.getElementById("path-disclaimer-decline");

if (disclaimerDecline) {
  disclaimerDecline.addEventListener("click", () => {
    createEmailDisclaimerResponse(
      false,
      modal.dataset.emailDisclaimerResponsePostUrl,
      modal.dataset.controllerName
    );
  });
}

const disclaimerAccept = document.getElementById("path-disclaimer-accept");

if (disclaimerAccept) {
  disclaimerAccept.addEventListener("click", () => {
    createEmailDisclaimerResponse(
      true,
      modal.dataset.emailDisclaimerResponsePostUrl,
      modal.dataset.controllerName
    );
  });
}

const disclaimerClose = document.querySelector(".mdc-dialog__close");

if (disclaimerClose) {
  disclaimerClose.addEventListener("click", () => {
    createEmailDisclaimerResponse(
      false,
      modal.dataset.emailDisclaimerResponsePostUrl,
      modal.dataset.controllerName
    );
  });
}
