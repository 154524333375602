// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
require("@rails/activestorage").start();

import "./sentry-init.js";

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
window.Stimulus = Application.start();

import "./style.scss";
import "./material-theme.scss";
import JQuery from "jquery";
window.$ = JQuery;
import "./navigation.scss";
import "./search-form.scss";
import "./devise.scss";
import "./modals.scss";
import "./resources-page.scss";
import "./accordions.scss";
import "./shared/accordion";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { RDAudioEventCapture } from "raindrops";

require.context("../images", true);

function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context("./participants/", true, /.(js|scss)$/));
importAll(require.context("./visitors/", true, /.(js|scss)$/));

RDAudioEventCapture.initialize(function (audioForm) {
  Rails.fire(audioForm, "submit");
});

import {
  RaincoatMDCBannerController,
  RaincoatMDCTextFieldController,
  RaincoatMDCButtonController,
  RaincoatMDCSelectController,
  RaincoatMDCCardController,
  RaincoatMDCListController,
  RaincoatMDCTopAppBarController,
  RaincoatMDCDialogController
} from "raincoat";

Stimulus.register("mdc--banner", RaincoatMDCBannerController);
Stimulus.register("mdc--text-field", RaincoatMDCTextFieldController);
Stimulus.register("mdc--button", RaincoatMDCButtonController);
Stimulus.register("mdc--select", RaincoatMDCSelectController);
Stimulus.register("mdc--card", RaincoatMDCCardController);
Stimulus.register("mdc--list", RaincoatMDCListController);
Stimulus.register("mdc--top-app-bar", RaincoatMDCTopAppBarController);
Stimulus.register("mdc--dialog", RaincoatMDCDialogController);

const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

Rails.start();
